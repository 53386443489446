// Date picker
$('#contact-charge-date').datepicker(
  {
    endDate: new Date()
  }
);
$("#contact-charge-date").on('change', function(){
  $('#contact-charge-date').datepicker('hide');
});

// Brand lightboxes
$(document).on("click", "a[data-lightbox-id='lb-brand']", function(e) {
  var image = $(this).attr('data-image');
  $("#lb-brand .modal-body img").attr('src', image);

  var title = $(this).attr('data-title');
  $("#lb-brand .modal-header h2.heading span").html(title);
});

/**
 * Dynamically call lightbox based on lightbox ID
 * Also, hAlign lightbox dynamically
 */
var lightbox = function()
{
  $(document).on("click", ".js-lb-trigger", function(e) {
    e.preventDefault();
    var lightboxID  = $(this).data('lightbox-id');
    var lightbox    = $('#' + lightboxID);
    var posLeft     = '-' + parseInt(lightbox.css('max-width')) / 2 + 'px';

    lightbox.modal();
    lightbox.css({
      "margin-left" : posLeft
    });
  });
}();

/**
 * Obfuscate/dynamically create email function
 */
var emailObfuscate = function()
{
  var el = $('.js-obfuscate-email');

  $.each(el, function() {
    var user    = $(this).data('user');
    var at      = "@";
    var domain  = $(this).data('domain');
    var tld     = $(this).data('tld');
    var mailto  = 'mailto:' + user + at + domain + '.' + tld;

    $(this).html(function(){
      $(this).attr('href', mailto);
      $(this).find('.text').text(user + at + domain + '.' + tld);
    });
  });
}();

/**/
var form_submit = function() {
  var myForm = $('#lb-form-contact').find('form');
  var formErrors = [];

  /**/
  myForm.on('submit', function(e) {
    e.preventDefault();

    var formData = {
      'name' :         $('[name="name"]').val(),
      'email' :        $('[name="email"]').val(),
      'subjectIndex' : $('[name="msg-subject"]').prop('selectedIndex'),
      'subject' :      $('[name="msg-subject"]').val(),
      'message' :      $('[name="message"]').val(),
      'chargeDate' :   $('[name="contact-charge-date"]').val(),
      'chargeAmount' : $('[name="charge-amount"]').val()
    };

    /* define errors */
    if(!formData.email.length)
    {
      formErrors.push('"Email" is required');
    }

    /**/
    if(!formData.message.length)
    {
      formErrors.push('"Message" is required');
    }

    /**/
    if(formErrors.length > 0)
    {
      formErrors.length = 0;
      return false;
    }
    else
    {
      var options = {
        type: myForm.attr('method'),
        url: myForm.attr('action'),
        data: formData,
        success : function() {
          showSuccess(true);
        }
      };

      $(this).ajaxSubmit(options);
    }

    /**
     * If form submission was successful, show message and hide form
     * @param hide
     */
    function showSuccess(hide)
    {
      var panel = $('#lightbox').find('[class*="panel-"]');

      if(hide)
      {
        panel.addClass('hide');
        $('#form-success').removeClass('hide');
        resetForm(true);
      }
      else
      {
        panel.removeClass('hide');
        $('#form-success').addClass('hide');
      }
    }

    /**
     * Reset form on success
     * @param resetForm
     */
    var resetForm = function(resetForm)
    {
      var lb = $('#lightbox');

      $('#lb-form-contact').on('hidden', function() {
        $(this).find('.control-group').removeClass('error');
        showSuccess(false);

        if(resetForm)
        {
          lb.find('form')[0].reset();
        }
      });
    };
  });
}();
